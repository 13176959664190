import React, { useState } from "react";
import { TextField, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PropTypes from "prop-types";

function MDCopyTextField({ label, value }) {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setCopySuccess(true);
  };

  return (
    <TextField
      label={label}
      value={value}
      fullWidth
      InputProps={{
        endAdornment: (
          <IconButton onClick={copyToClipboard} color="secondary" size="small">
            <ContentCopyIcon />
          </IconButton>
        ),
      }}
      helperText={copySuccess ? "Copied!" : ""}
    />
  );
}

MDCopyTextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default MDCopyTextField;
