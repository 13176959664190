import { ConversationList, Conversation } from "@chatscope/chat-ui-kit-react";
import PropTypes from "prop-types";

function JCConversationList({ chats, selectedChat, setSelectedChat, setFetchChatsCalled }) {
  if (!chats || !selectedChat) {
    return null;
  }
  return (
    <ConversationList>
      {chats.map((chat) => (
        <Conversation
          key={chat.chat_id}
          name={chat.chat_name}
          info={`${chat.chat_type} ${chat.last_user_query ? `| ${chat.last_user_query}` : ""}`}
          onClick={() => setSelectedChat(chat) && setFetchChatsCalled(false)}
          unreadDot={chat.model === "gpt-4"}
          active={chat.chat_id === selectedChat.chat_id}
          sx={{ mx: 1 }}
        />
      ))}
    </ConversationList>
  );
}
JCConversationList.defaultProps = {
  chats: [],
  selectedChat: {},
  setSelectedChat: () => {},
};

JCConversationList.propTypes = {
  chats: PropTypes.arrayOf(
    PropTypes.shape({
      chat_id: PropTypes.string,
      chat_name: PropTypes.string,
      last_user_query: PropTypes.string,
      model: PropTypes.string,
    })
  ),
  selectedChat: PropTypes.shape({
    chat_id: PropTypes.string,
    chat_name: PropTypes.string,
    last_user_query: PropTypes.string,
    model: PropTypes.string,
  }),
  setSelectedChat: PropTypes.func,
  setFetchChatsCalled: PropTypes.func.isRequired,
};

export default JCConversationList;
