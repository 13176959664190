import { Card, Typography, Grid } from "@mui/material";
import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";

function ConnectionCard({ connection }) {
  return (
    <Card sx={{ p: 2 }}>
      <Grid container justifyContent="space-between">
        <Typography variant="h4">{connection.connection_name}</Typography>
        <Switch checked={connection.enabled} id={connection.connection_name} />
      </Grid>
      <Typography variant="body2">Project ID: {connection.project_id}</Typography>
      <Typography variant="body2">Dataset ID: {connection.dataset_id}</Typography>
      {Object.keys(connection.schema).map((table) => (
        <Grid
          container
          justifyContent="space-between"
          key={`${connection.connection_name}/${table}`}
        >
          <Typography variant="body2">{table}</Typography>
          <Switch checked={connection.schema[table].enabled} />
        </Grid>
      ))}
    </Card>
  );
}

ConnectionCard.propTypes = {
  connection: PropTypes.shape({
    enabled: PropTypes.bool,
    connection_name: PropTypes.string,
    project_id: PropTypes.string,
    dataset_id: PropTypes.string,
    schema: PropTypes.shape({
      table_name: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
    }),
  }).isRequired,
};

export default ConnectionCard;
