import { useEffect } from "react";
import { Grid, Paper, Box } from "@mui/material";
import MuiMarkdown from "mui-markdown";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import overrides from "../MuiMarkdownOverrides";
import markdown from "./content";

function ReadMe() {
  useEffect(() => {
    document.title = "Read Me - Joon Solutions";
  }, []);
  return (
    <DashboardLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} xl={8}>
          <Paper sx={{ padding: 3 }} elevation={8} mt={5}>
            <Box style={{ maxHeight: "80vh", overflow: "auto" }}>
              <MuiMarkdown overrides={overrides}>{markdown}</MuiMarkdown>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default ReadMe;
