import { defaultOverrides } from "mui-markdown";

const overrides = {
  ...defaultOverrides,
  code: {
    props: {
      style: {
        fontSize: 13,
        backgroundColor: "rgba(126, 126, 126, 0.1)",
        borderRadius: "4px",
        margin: "0 0.2rem",
        padding: "0.3rem 0.3rem",
      },
    },
  },
  p: {
    props: {
      variant: "body3",
      style: { marginBottom: 16, fontSize: 16, marginTop: 6, color: "#344767" },
    },
  },
  span: {
    props: {
      variant: "body3",
      style: { marginBottom: 16, fontSize: 16, marginTop: 6, color: "#344767" },
    },
  },
  h1: {
    props: {
      variant: "h2",
      style: { marginBottom: 16, fontSize: 32, color: "#344767" },
    },
  },
  h2: {
    props: {
      variant: "h3",
      style: { marginBottom: 16, fontSize: 24, color: "#344767" },
    },
  },
  h3: {
    props: {
      variant: "h4",
      style: { marginBottom: 16, fontSize: 20, color: "#344767" },
    },
  },
  li: {
    props: {
      variant: "body3",
      style: { marginBottom: 6, marginTop: 6, marginLeft: 32, fontSize: 16, color: "#344767" },
    },
  },
  link: {
    props: {
      style: { color: "blue", textDecoration: "underline" },
    },
  },
  pre: {
    ...defaultOverrides.pre,
    props: {
      ...defaultOverrides.pre.props,
      style: {
        ...defaultOverrides.pre.props.style,
        marginBottom: 16,
        fontSize: 12,
        color: "#344767",
      },
    },
  },
};

export default overrides;
