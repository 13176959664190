import {
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
} from "firebase/auth";
import MDAlert from "components/MDAlert";
import { analytics } from "FirebaseConfig";
import { logEvent } from "firebase/analytics";

function updateUserInformation(userId, userData) {
  try {
    const { email } = userData;
    const organization = email.match(/^[^@]+@(.+\..+)$/)[1];
    const updatedUserData = { ...userData, organization };
    const response = fetch(`${process.env.REACT_APP_API_HOST_NAME}/api/v1/user/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userData.accessToken}`,
      },
      body: JSON.stringify(updatedUserData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = response.json();
    return responseData;
  } catch (error) {
    throw Error(error.message);
  }
}

export const handleSSOSignIn = async (
  setUser,
  navigate,
  requestedPath,
  setErrorMsg,
  auth,
  provider
) => {
  try {
    const result = await signInWithPopup(auth, provider);
    setUser(result.user);
    navigate(requestedPath);
    updateUserInformation(result.user.uid, result.user);
    logEvent(analytics, "login", {
      method: "google",
    });
  } catch (error) {
    if (error.code === "auth/internal-error") {
      setErrorMsg("Your email domain is not whitelisted. Please contact us for access.");
    } else {
      setErrorMsg(error.message);
    }
  }
};

export const handleEmailPasswordSignIn = (
  event,
  auth,
  setUser,
  setErrorMsg,
  navigate,
  requestedPath
) => {
  event.preventDefault();
  const email = document.getElementById("email").value;
  const password = document.getElementById("password").value;
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      setUser(userCredential.user);
      setErrorMsg("");
      navigate(requestedPath);
    })
    .catch((error) => {
      if (error.code === "auth/user-not-found") {
        setErrorMsg("User not found, please sign up first.");
      } else {
        setErrorMsg(error.message);
      }
    });
};

export const handleRegistration = (event, auth, setErrorMsg, setUser, navigate) => {
  event.preventDefault();
  const email = document.getElementById("email").value;
  const password = document.getElementById("password").value;
  const confirmPassword = document.getElementById("confirm-password").value;
  const name = document.getElementById("name").value;
  const toc = document.getElementById("toc");

  if (password !== confirmPassword) {
    setErrorMsg("Passwords do not match");
    return;
  }
  if (!toc.checked) {
    setErrorMsg("Please accept the terms and conditions");
    return;
  }

  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // User created successfully
      const { user } = userCredential;
      updateProfile(user, {
        displayName: name,
      });
      setUser(userCredential.user);
      updateUserInformation(user.uid, user);
      navigate("/");
    })
    .catch((error) => {
      if (error.code === "auth/internal-error") {
        setErrorMsg("Your email domain is not whitelisted. Please contact us for access.");
      } else {
        setErrorMsg(error.message);
      }
    });
};

export const handleSignOut = async (auth, navigate) => {
  try {
    await signOut(auth);
    navigate("/sign-in");
  } catch (error) {
    <MDAlert>{error.message}</MDAlert>;
  }
};
