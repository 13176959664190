/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts

import SignOut from "layouts/authentication/sign-out";
import Chat from "layouts/joonai/chat";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// Images
import profilePicture from "assets/images/avatar-placeholder.png";

import AuthenticatedLayout from "AuthenticatedLayout";
import { useUserContext } from "context/UserContext";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import DnsIcon from "@mui/icons-material/Dns";
import LogoutIcon from "@mui/icons-material/Logout";
import Icon from "@mui/material/Icon";
import MenuBookIcon from "@mui/icons-material/MenuBook";

import SignInCover from "layouts/authentication/sign-in";
import SignUpCover from "layouts/authentication/sign-up";
import Connection from "layouts/joonai/connection";
import Schema from "layouts/joonai/schema";
import ReadMe from "layouts/joonai/readme";
import Account from "layouts/account/settings";

function getAllRoutes() {
  const { user } = useUserContext();

  const allRoutes = [
    {
      type: "collapse",
      name: user?.displayName,
      key: "user-profile",
      icon: <MDAvatar src={user?.photoURL ?? profilePicture} alt={user?.displayName} size="sm" />,
      noCollapse: true,
      route: "/account",
      component: (
        <AuthenticatedLayout>
          <Account />
        </AuthenticatedLayout>
      ),
    },
    { type: "divider", key: "divider-0" },
    {
      name: "Read Me",
      type: "collapse",
      noCollapse: true,
      key: "readme",
      route: "/JoonAI/readme",
      icon: <MenuBookIcon fontSize="medium">menu_book_icon</MenuBookIcon>,
      component: (
        <AuthenticatedLayout>
          <ReadMe />
        </AuthenticatedLayout>
      ),
    },
    {
      name: "Connection",
      type: "collapse",
      noCollapse: true,
      key: "connection",
      route: "/JoonAI/connection",
      icon: <DnsIcon fontSize="medium">dns_icon</DnsIcon>,
      component: (
        <AuthenticatedLayout>
          <Connection />
        </AuthenticatedLayout>
      ),
    },
    {
      name: "JoonGPT",
      type: "collapse",
      noCollapse: true,
      key: "joongpt",
      route: "/JoonAI/chat",
      icon: <SmartToyIcon fontSize="medium">smart_toy</SmartToyIcon>,
      component: (
        <AuthenticatedLayout>
          <Chat />
        </AuthenticatedLayout>
      ),
    },

    {
      name: "Schema",
      type: "collapse",
      noCollapse: true,
      key: "schema",
      route: "/JoonAI/schema",
      icon: <SmartToyIcon fontSize="medium">smart_toy</SmartToyIcon>,
      component: (
        <AuthenticatedLayout>
          <Schema />
        </AuthenticatedLayout>
      ),
    },
    { type: "divider", key: "divider-1" },
    {
      name: "Sign Out",
      type: "collapse",
      noCollapse: true,
      key: "sign-out",
      icon: <LogoutIcon fontSize="medium">logout</LogoutIcon>,
      route: "/sign-out",
      component: <SignOut />,
    },
    {
      type: "collapse",
      name: "Authentication",
      key: "authentication",
      icon: <Icon fontSize="medium">content_paste</Icon>,
      collapse: [
        {
          name: "Sign In",
          key: "sign-in",
          route: "/sign-in",
          component: <SignInCover />,
        },
        {
          name: "Sign Up",
          key: "sign-up",
          route: "/sign-up",
          component: <SignUpCover />,
        },
      ],
    },
  ];
  return allRoutes;
}

export default getAllRoutes;
