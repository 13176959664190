import CardContent from "@mui/material/CardContent";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Autocomplete } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useState } from "react";
import PropTypes from "prop-types";

function JCModifyConnection({ connections, apiHostName, setfetchConnectionsCalled, user }) {
  const [primaryErrorMsg, setPrimaryErrorMsg] = useState("");

  const handleAlertClose = () => {
    setPrimaryErrorMsg("");
  };

  async function deleteConnection() {
    const selectedConnection = document.getElementById("selected_connection").value;
    if (selectedConnection === "") {
      setPrimaryErrorMsg("Please select a connection");
      return;
    }
    try {
      const organization = user.email.match(/^[^@]+@(.+\..+)$/)[1];
      const response = await fetch(
        `${apiHostName}/api/v1/connection/${organization}/${selectedConnection}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      if (response.ok) {
        document.getElementById("selected_connection").defaultValue = "";
        setfetchConnectionsCalled(false);
      } else {
        setPrimaryErrorMsg(response.statusText);
      }
    } catch (error) {
      setPrimaryErrorMsg(error.message);
    }
  }
  return (
    <CardContent>
      <MDTypography gutterBottom variant="h5" component="div" mt={3}>
        Modify a connection
      </MDTypography>
      <MDTypography variant="body2" color="secondary">
        To modify a connection, use the same connection name as the existing connection. To delete a
        connection, select the connection name and click on the delete button.
      </MDTypography>
      <Autocomplete
        id="selected_connection"
        options={connections.map((connection) => connection.connection_name)}
        renderInput={(params) => <MDInput {...params} label="Connection Name:" />}
        sx={{ mb: 2, mt: 3 }}
      />
      {primaryErrorMsg && (
        <MDAlert color="error" dismissible onClick={handleAlertClose}>
          <MDTypography variant="button" color="light" fontWeight="regular">
            {primaryErrorMsg}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox mt={3} mb={1} style={{ display: "flex" }} justifyContent="right">
        <MDButton variant="contained" color="primary" onClick={() => deleteConnection()}>
          Delete
        </MDButton>
      </MDBox>
    </CardContent>
  );
}

JCModifyConnection.propTypes = {
  connections: PropTypes.arrayOf(PropTypes.object).isRequired,
  apiHostName: PropTypes.string.isRequired,
  setfetchConnectionsCalled: PropTypes.func.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default JCModifyConnection;
