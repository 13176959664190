import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConversationHeader, Avatar } from "@chatscope/chat-ui-kit-react";

function JCChatHeader({ chat, joonLogo, apiHostName, selectedChatId, user, setFetchChatsCalled }) {
  async function deleteChat(event) {
    event.preventDefault();
    try {
      const response = await fetch(`${apiHostName}/api/v1/chat`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          chat_id: selectedChatId,
          user_id: user.uid,
        }),
      });
      if (!response.ok) {
        throw new Error(`Failed to delete chat: ${response.status}`);
      }
      setFetchChatsCalled(false);
    } catch (error) {
      throw new Error(error);
    }
  }
  return (
    <ConversationHeader>
      <Avatar src={joonLogo} name="JoonGPT" />
      <ConversationHeader.Content
        userName={chat.chat_name}
        info={`${chat.chat_type} | ${chat.model} | ${chat.created_at.split("T")[0]}`}
      />
      <ConversationHeader.Actions>
        <DeleteIcon
          fontSize="medium"
          onClick={(event) => deleteChat(event)}
          sx={{
            cursor: "pointer",
            color: "secondary.main",
            "&:hover": {
              color: "error.main",
            },
          }}
        />
      </ConversationHeader.Actions>
    </ConversationHeader>
  );
}

JCChatHeader.propTypes = {
  chat: PropTypes.shape({
    chat_id: PropTypes.string.isRequired,
    chat_type: PropTypes.string.isRequired,
    chat_name: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
  }).isRequired,
  joonLogo: PropTypes.string.isRequired,
  apiHostName: PropTypes.string.isRequired,
  selectedChatId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
  }).isRequired,
  setFetchChatsCalled: PropTypes.func.isRequired,
};

export default JCChatHeader;
