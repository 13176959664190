import { Grid, Paper } from "@mui/material";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import connections from "layouts/joonai/schema/connections.json";
import { v4 as uuidv4 } from "uuid";
import ConnectionCard from "./ConnectionCard";

function Schema() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container justifyContent="center">
        <Grid item xs={12} textAlign="center" mb={4}>
          <MDTypography variant="h3" fontWeight="bold">
            Manage Enabled Schema
          </MDTypography>
        </Grid>
        <Grid item xs={9}>
          <Paper>
            {connections.map((connection) => (
              <ConnectionCard key={uuidv4()} connection={connection} />
            ))}
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Schema;
