import React, { useState } from "react";
import MDBox from "@mui/material/Box";
import { Card, CardContent, Autocomplete } from "@mui/material";
import MDTypography from "@mui/material/Typography";
import MDInput from "components/MDInput";
import MDCopyTextField from "components/MDCopyTextField";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import DoneIcon from "@mui/icons-material/Done";
import PropTypes from "prop-types";

function JCAddConnection({ user, apiHostName, setfetchConnectionsCalled, loading, setLoading }) {
  const [completed, setCompleted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleAlertClose = () => {
    setErrorMsg("");
  };

  async function getDwhSchema(event) {
    event.preventDefault();
    setLoading(true);
    const connectionType = document.getElementById("connection_type").value;
    const projectId = document.getElementById("project_id").value;
    const datasetId = document.getElementById("dataset_id").value;
    const connectionName = document.getElementById("connection_name").value;
    const organization = user.email.match(/^[^@]+@(.+\..+)$/)[1];
    try {
      const response = await fetch(`${apiHostName}/api/v1/connection`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          user_id: user.uid,
          organization,
          project_id: projectId,
          dataset_id: datasetId,
          connection_name: connectionName,
          connection_type: connectionType,
        }),
      });
      if (response.ok) {
        setLoading(false);
        setCompleted(true);
        setTimeout(() => setCompleted(false), 2000);
        document.getElementById("connection_name").value = "";
        document.getElementById("project_id").value = "";
        document.getElementById("dataset_id").value = "";
        setfetchConnectionsCalled(false);
      } else if (response.status === 409) {
        setErrorMsg("Connection already exists");
        setLoading(false);
      } else {
        setErrorMsg(response.statusText);
        setLoading(false);
      }
    } catch (error) {
      setErrorMsg(error.message);
      setLoading(false);
    }
  }

  return (
    <Card>
      <CardContent spacing={3}>
        <MDBox component="form" role="form" onSubmit={(event) => getDwhSchema(event)}>
          <MDTypography gutterBottom variant="h5" component="div" mt={3}>
            Add a Connection
          </MDTypography>
          <MDTypography variant="body2" color="secondary">
            Add a connection to your BigQuery or Snowflake (coming soon) data warehouse.
          </MDTypography>
          <Autocomplete
            id="connection_type"
            defaultValue="BigQuery"
            options={["BigQuery", "Snowflake (not implemented)"]}
            renderInput={(params) => <MDInput {...params} label="Connection Type:" />}
            sx={{ mb: 2, mt: 3 }}
          />
          <MDInput id="connection_name" fullWidth sx={{ mb: 2 }} label="Connection Name:" />
          <MDInput id="project_id" fullWidth sx={{ mb: 2 }} label="Project:" />
          <MDInput id="dataset_id" fullWidth sx={{ mb: 3 }} label="Dataset:" />
          <MDTypography variant="subtitle2" mb={3} ml={0.5}>
            Please grant `bigquery.job.user` and `bigquery.data.viewer` to the following service
            account:
          </MDTypography>
          <MDCopyTextField
            label="Service Account"
            value="joon-customer-portal@appspot.gserviceaccount.com"
          />
          <MDBox mt={3} mb={1} style={{ display: "flex" }}>
            <MDButton
              type="submit"
              variant="gradient"
              color="info"
              fullWidth
              mt={3}
              style={{ flexGrow: 1 }}
              disabled={loading}
            >
              Add connection
            </MDButton>
            {completed && <DoneIcon fontSize="large" style={{ color: "green", marginLeft: 10 }} />}
          </MDBox>
          {errorMsg && (
            <MDAlert color="error" dismissible onClick={handleAlertClose}>
              <MDTypography variant="button" color="light" fontWeight="regular">
                {errorMsg}
              </MDTypography>
            </MDAlert>
          )}
        </MDBox>
      </CardContent>
    </Card>
  );
}

JCAddConnection.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    accessToken: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  apiHostName: PropTypes.string.isRequired,
  setfetchConnectionsCalled: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default JCAddConnection;
