/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { app } from "FirebaseConfig";
import MDAlert from "components/MDAlert";
import { useUserContext } from "context/UserContext";
// import GitHubIcon from "@mui/icons-material/GitHub";
import { handleRegistration, handleSSOSignIn } from "layouts/authentication/FirebaseAuth";
import GoogleButton from "react-google-button";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

function SignUp() {
  const auth = getAuth(app);
  const { setUser } = useUserContext();
  const [errorMsg, setErrorMsg] = useState(null);
  const requestedPath = sessionStorage.getItem("requestedPath") || "/";
  const googleProvider = new GoogleAuthProvider();
  const navigate = useNavigate();

  const handleAlertClose = () => {
    setErrorMsg("");
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
        </MDBox>
        <Grid container pt={4} pb={3} justifyContent="center">
          <Grid item mb={3}>
            <GoogleButton
              type="light"
              label="Sign up with Google"
              onClick={() =>
                handleSSOSignIn(setUser, navigate, requestedPath, setErrorMsg, auth, googleProvider)
              }
            />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body2" align="center">
              or more traditionally
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            component="form"
            role="form"
            onSubmit={(event) => handleRegistration(event, auth, setErrorMsg, setUser, navigate)}
          >
            <MDBox mb={2}>
              <MDInput id="name" type="text" label="Name" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput id="email" type="email" label="Email" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                id="password"
                type="password"
                label="Password"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                id="confirm-password"
                type="password"
                label="Confirm Password"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox id="toc" />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {errorMsg && (
                <MDAlert color="error" dismissible onClick={handleAlertClose}>
                  <MDTypography variant="button" color="light" fontWeight="regular">
                    {errorMsg}
                  </MDTypography>
                </MDAlert>
              )}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign up
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
    </CoverLayout>
  );
}

export default SignUp;
