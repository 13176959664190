import React from "react";
import PropTypes from "prop-types";
import { useUserContext } from "context/UserContext";
import { Navigate, useLocation } from "react-router-dom";

function AuthenticatedLayout({ children }) {
  const { user } = useUserContext();
  const location = useLocation();

  if (!user) {
    sessionStorage.setItem("requestedPath", location.pathname);
    return <Navigate to="/sign-in" />;
  }

  return children;
}

AuthenticatedLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticatedLayout;
