const markdown = `
# Getting started with JoonGPT

## TLDR
There are two types of chats: 
* JoonGPT: Basically ChatGPT under the hood. 
* Data Chat (we may need a better name): ChatGPT, but connected to a data warehouse and can generate SQL queries and return results.

## Getting Started
### Model type

There are two models to choose from:
* \`gpt-3.5-turbo\`: Optimized for speed, fair reasoning, less concise, and less expensive.
* \`gpt-4\`: Optimized for reasoning, slower speed, more concise, and more expensive.

**Use \`gpt-3.5-turbo\` for most of your chats and change to \`gpt-4\` if you need more reasoning power.**

### JoonGPT chat
Navigate to the JoonGPT tab and click on the \`Create a new chat\` button. Select JoonGPT as the chat type and choose a name for your chat. Click on the \`Create\` button to create your chat. 
You can then ask it any work-related question you want.

### Data Chat
Navigate to the [JoonGPT](./chat) tab and click on the \`Create a new chat\` button. Select Data Chat as the chat type and choose a name for your chat. Click on the \`Create\` button to create your chat.
Select an existing conneciton to a data warehouse or use the \`Connection\` tab to create a new connection. We only support BigQuery at the moment, but we will add more data warehouses in the future.
Be careful about adding a client's data warehouse as you may need permission to do so.

Once you have selected a connection, a list of tables in that schema will appear. Currently we only support tables within the same schema. For optimized performance, select only the tables you need.
The ability to modify the selected tables will be added in the future. The service will only return the first 500 rows of a query. The model has the context of the connection provided along with the last previous 4 query pair (prompt and answer).

You can ask any data-related question you want. For example, you can ask:
* List top 10 customers by revenue
* What did customer A buy?
* How much did customer A spend per category?
* What is the average revenue per customer?

### Tips for asking questions
* Use Chrome for maximum compatibility.
* Provide as much context as possible. For example, if you are asking about revenue, specify the time period, and whether you want gross or net revenue. If you are asking about a specific customer, provide the customer's name or ID.
* Correct the model if it makes a mistake. For example, if the model is trying to query a column that doesn't exist, correct it by providing the correct column name.
* Use the error message to ask the model to correct itself. 
* If the model is not understanding your question, try rephrasing it. For example, if you are asking about revenue, try asking about sales instead.
* If the model is not returning the results you want, try rephrasing your question.
* Sometimes the model will be tripped up by previous incorrect answers. If this happens, try asking the model to ignore previous answers. If the problem persists, try creating a new chat.
* Please use the thumbs up and thumbs down buttons to provide feedback to the model. This will help the model learn and improve over time.
* If the chat returns a blank response, try refreshing the page. 
`;

export default markdown;
