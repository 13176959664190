import CardContent from "@mui/material/CardContent";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useEffect } from "react";
import PropTypes from "prop-types";

function JCExistingConnections({
  apiHostName,
  setLoading,
  connections,
  setConnections,
  fetchConnectionsCalled,
  setfetchConnectionsCalled,
  user,
}) {
  async function fetchConnections() {
    if (!fetchConnectionsCalled) {
      setLoading(true);
      const organization = user.email.match(/^[^@]+@(.+\..+)$/)[1];
      const response = await fetch(`${apiHostName}/api/v1/connection/${organization}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      const data = await response.json();
      setConnections(data);
      setfetchConnectionsCalled(true);
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchConnections();
  }, [fetchConnectionsCalled]);
  return (
    <CardContent>
      <MDTypography gutterBottom variant="h5" component="div" mt={3}>
        Existing connection(s)
      </MDTypography>
      <MDTypography variant="body2" color="secondary">
        {!fetchConnectionsCalled
          ? "Add a connection to your data warehouse to get started."
          : "Here are your team's existing connections:"}
      </MDTypography>
      <DataTable
        showPagination={false}
        showSearch={false}
        showTotalEntries={false}
        table={{
          columns: [
            { Header: "Connection Name", accessor: "connection_name" },
            { Header: "Connection Type", accessor: "connection_type" },
            { Header: "Project", accessor: "project_id" },
            { Header: "Dataset", accessor: "dataset_id" },
          ],
          rows: connections,
        }}
      />
    </CardContent>
  );
}

JCExistingConnections.propTypes = {
  apiHostName: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  connections: PropTypes.arrayOf(PropTypes.object).isRequired,
  setConnections: PropTypes.func.isRequired,
  fetchConnectionsCalled: PropTypes.bool.isRequired,
  setfetchConnectionsCalled: PropTypes.func.isRequired,
  user: PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default JCExistingConnections;
