import React, { useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { MessageList, Avatar, TypingIndicator } from "@chatscope/chat-ui-kit-react";
import PropTypes from "prop-types";
import { Grid, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import profilePicture from "assets/images/avatar-placeholder.png";
import MuiMarkdown from "mui-markdown";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableChartIcon from "@mui/icons-material/TableChart";
import MDTypography from "components/MDTypography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import overrides from "../MuiMarkdownOverrides";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{ color: "secondary.main" }} />
      <GridToolbarFilterButton sx={{ color: "secondary.main" }} />
      <GridToolbarExport sx={{ color: "secondary.main" }} />
    </GridToolbarContainer>
  );
}
function JCChatContent({
  user,
  selectedChat,
  joonLogo,
  sendingMessage,
  isMountedRef,
  apiHostName,
  selectedMessages,
}) {
  const messagesEndRef = useRef(null);

  function scrollToBottom() {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  }

  useEffect(() => {
    scrollToBottom();
  }, [selectedMessages]);

  function renderTable(content) {
    if (!isMountedRef.current) return null;
    const rows = JSON.parse(content);
    const columns = Object.keys(rows[0] || {}).map((key) => ({
      field: key,
      headerName: key,
      width: 150,
    }));

    return (
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={uuidv4}
        autoHeight
        disableRowSelectionOnClick
        slots={{
          toolbar: CustomToolbar,
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#2e3f5c",
            fontWeight: "bold",
          },
          "& .MuiDataGrid-cellContent": {
            color: "#2e3f5c",
          },
        }}
      />
    );
  }

  function dataAccordion(content, index) {
    const lastDataMessageIndex = selectedMessages
      .map((message) => message.role)
      .lastIndexOf("data");
    return (
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        className="data-accordion"
        defaultExpanded={
          index === lastDataMessageIndex &&
          selectedMessages[selectedMessages.length - 1].role === "data"
        }
        id={index}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <TableChartIcon fontSize="medium" sx={{ mr: 1 }} color="dark" />
          <MDTypography variant="body3" color="dark">
            View Data
          </MDTypography>
        </AccordionSummary>
        <AccordionDetails>{renderTable(content)}</AccordionDetails>
      </Accordion>
    );
  }

  function jsonTextToCsv(jsonText) {
    const rows = JSON.parse(jsonText);
    const columns = Object.keys(rows[0] || {});
    const csvContent = [columns.join(",")];
    rows.forEach((row) => {
      const values = columns.map((column) => row[column]);
      csvContent.push(values.join(","));
    });
    return csvContent.join("\n");
  }

  function copyToClipboard(value) {
    let copyText = value;
    if (value.startsWith("```sql") && value.endsWith("```")) {
      copyText = value.slice(6, -3);
    } else if (value.startsWith("```") && value.endsWith("```")) {
      copyText = value.slice(3, -3);
    }
    if (value.startsWith("[{") && value.endsWith("}]")) {
      copyText = jsonTextToCsv(value);
    }
    navigator.clipboard.writeText(copyText);
  }

  async function handleRating(messageId, action) {
    await fetch(`${apiHostName}/api/v1/chat/rate/${selectedChat.chat_id}/${messageId}/${action}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
    });
  }

  return (
    <MessageList typingIndicator={sendingMessage && <TypingIndicator content={sendingMessage} />}>
      <MessageList.Content>
        <Grid mb={4} />
        <Grid container justifyContent="center" spacing={0}>
          {selectedMessages
            ?.filter(
              (message) =>
                ["assistant", "user", "data", "error"].includes(message.role) &&
                !message?.content?.startsWith(">>")
            )
            .map((message, index) => (
              <Grid item xs={12} key={uuidv4()}>
                <Grid
                  sx={{
                    bgcolor: message.role === "user" ? "light.main" : "main",
                    p: 2,
                  }}
                >
                  <Grid container justifyContent="flex-start">
                    <Grid item xs={1}>
                      {["assistant", "data", "error"].includes(message.role) && (
                        <Avatar src={joonLogo} name="JoonGPT" />
                      )}
                      {message.role === "user" && (
                        <Avatar src={user.photoURL ?? profilePicture} name="User Avatar" />
                      )}
                    </Grid>
                    <Grid item xs={10} id={index}>
                      {message.role !== "data" && (
                        <MuiMarkdown
                          overrides={{
                            ...overrides,
                            p: {
                              props: {
                                ...overrides.p.props,
                                style: {
                                  ...overrides.p.props.style,
                                  color: message.role === "error" ? "red" : "inherit",
                                },
                              },
                            },
                            span: {
                              props: {
                                ...overrides.span.props,
                                style: {
                                  ...overrides.span.props.style,
                                  color: message.role === "error" ? "red" : "inherit",
                                },
                              },
                            },
                          }}
                        >
                          {message.content}
                        </MuiMarkdown>
                      )}
                      {message.role === "data" && dataAccordion(message.content, index)}
                    </Grid>
                    {message.role !== "user" && (
                      <Grid item xs={1} padding={1}>
                        <Grid container spacing={1} justifyContent="space-between">
                          <Grid item>
                            <ContentCopyIcon
                              color="secondary"
                              sx={{
                                cursor: "pointer",
                                color: "secondary.main",
                                "&:hover": {
                                  color: "error.main",
                                },
                              }}
                              onClick={() => copyToClipboard(message.content)}
                            />
                          </Grid>
                          <Grid item>
                            <ThumbUpOffAltIcon
                              color="secondary"
                              onClick={() => {
                                handleRating(message.id, "thumb_up");
                              }}
                              sx={{
                                cursor: "pointer",
                                color: "secondary.main",
                                "&:hover": {
                                  color: "error.main",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <ThumbDownOffAltIcon
                              color="secondary"
                              onClick={() => {
                                handleRating(message.id, "thumb_down");
                              }}
                              sx={{
                                cursor: "pointer",
                                color: "secondary.main",
                                "&:hover": {
                                  color: "error.main",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid mb={6}>
          <div ref={messagesEndRef} />
        </Grid>
      </MessageList.Content>
    </MessageList>
  );
}

JCChatContent.defaultProps = {
  selectedMessages: [],
};

JCChatContent.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    photoURL: PropTypes.string,
    accessToken: PropTypes.string.isRequired,
  }).isRequired,
  selectedChat: PropTypes.shape({
    chat_id: PropTypes.string.isRequired,
  }).isRequired,
  joonLogo: PropTypes.string.isRequired,
  sendingMessage: PropTypes.string.isRequired,
  isMountedRef: PropTypes.shape({
    current: PropTypes.bool.isRequired,
  }).isRequired,
  apiHostName: PropTypes.string.isRequired,
  selectedMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      content: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    })
  ),
};

export default JCChatContent;
