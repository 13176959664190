/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { useNavigate, Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import MuiLink from "@mui/material/Link";
// import GoogleIcon from "@mui/icons-material/Google";
// import GitHubIcon from "@mui/icons-material/GitHub";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import { app } from "FirebaseConfig";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { useUserContext } from "context/UserContext";
import { handleSSOSignIn, handleEmailPasswordSignIn } from "layouts/authentication/FirebaseAuth";
import GoogleButton from "react-google-button";
import MDAlert from "components/MDAlert";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

function SignIn() {
  const auth = getAuth(app);
  const { setUser } = useUserContext();
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();
  const requestedPath = sessionStorage.getItem("requestedPath") || "/";
  const googleProvider = new GoogleAuthProvider();
  // const githubProvider = new GithubAuthProvider();

  const handleAlertClose = () => {
    setErrorMsg("");
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={1} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item padding={2}>
              <MDTypography variant="body2" color="white">
                Sign in to start using Joon&apos;s services.
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container justifyContent="center">
          <Grid item mb={3} mt={4}>
            <GoogleButton
              type="light"
              onClick={() =>
                handleSSOSignIn(setUser, navigate, requestedPath, setErrorMsg, auth, googleProvider)
              }
            />
          </Grid>
          <Grid item>
            <MDTypography variant="body2" color="text" fontWeight="regular">
              Or sign in with credentials
            </MDTypography>
          </Grid>
        </Grid>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            onSubmit={(event) =>
              handleEmailPasswordSignIn(event, auth, setUser, setErrorMsg, navigate, requestedPath)
            }
          >
            <MDBox mb={2}>
              <MDInput
                id="email"
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                id="password"
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                placeholder="************"
                InputLabelProps={{ shrink: true }}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {errorMsg && (
                <MDAlert color="error" dismissible onClick={handleAlertClose}>
                  <MDTypography variant="button" color="light" fontWeight="regular">
                    {errorMsg}
                  </MDTypography>
                </MDAlert>
              )}
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default SignIn;
