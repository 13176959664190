import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Box,
} from "@mui/material";
import { useState } from "react";
import MDTypography from "components/MDTypography";
import { Container } from "@mui/system";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import MDAlert from "components/MDAlert";
import ChatIcon from "@mui/icons-material/Chat";

function JCCreateChatDialog({ user, apiHostName, setFetchChatsCalled, setSelectedChat }) {
  const [open, setOpen] = useState(false);
  const [connections, setConnections] = useState([]);
  const [chatType, setChatType] = useState("JoonGPT");
  const userOrgnaization = user.email?.split("@")[1];
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [selectedTables, setSelectedTables] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  function fetchConnections() {
    fetch(`${apiHostName}/api/v1/connection/${userOrgnaization}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setConnections(data));
  }

  const handleChange = (event) => {
    setChatType(event.target.value);
    fetchConnections();
    // setSelectedConnection(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function createChat(event) {
    event.preventDefault();
    try {
      const chatName = document.getElementById("chat_name").value;
      const model = document.getElementById("model").value;
      const connection = document.getElementById("connection")?.value;
      if (chatName === "") {
        setErrorMessage("Chat name cannot be empty");
        return false;
      }
      if (model === "") {
        setErrorMessage("Model cannot be empty");
        return false;
      }
      if (connection === "" && chatType === "Data Chat") {
        setErrorMessage("Connection cannot be empty");
        return false;
      }
      if (selectedTables.length === 0 && chatType === "Data Chat") {
        setErrorMessage("You must select at least one table");
        return false;
      }
      const response = await fetch(`${apiHostName}/api/v1/chat/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          chat_name: chatName,
          model,
          user_id: user.uid,
          chat_type: chatType,
          connection,
          tables: selectedTables,
        }),
      });
      const data = await response.json();
      setFetchChatsCalled(false);
      setSelectedChat(data);
      return true;
    } catch (error) {
      setErrorMessage(error);
      return false;
    }
  }
  async function handleCreateChat(event) {
    const chatCreated = await createChat(event);
    if (chatCreated) {
      handleClose();
      setErrorMessage(null);
      document.getElementById("chat_name").value = "";
    }
  }

  function renderSelectSchema() {
    if (selectedConnection) {
      const connection = connections.find((con) => con.connection_name === selectedConnection);
      const tables = Object.keys(connection.schema);
      const ITEM_HEIGHT = 48;
      const ITEM_PADDING_TOP = 8;
      const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };

      return (
        <Grid container>
          <Grid item xs={12} mb={2} mt={2}>
            <MDTypography variant="button">
              Select the tables you want to use for your chat. For opitmal performance, select just
              enough tables to generate the queries you want.
            </MDTypography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <FormControl fullWidth sx={{ height: "50px" }}>
              <InputLabel>Tables</InputLabel>
              <Select
                id="schema"
                multiple
                value={selectedTables}
                defaultValue={null}
                MenuProps={MenuProps}
                input={<OutlinedInput label="Tables" sx={{ height: "50px" }} />}
                sx={{ height: "50px" }}
                onChange={(event) => setSelectedTables(event.target.value)}
              >
                {tables.sort().map((table) => (
                  <MenuItem key={table} value={table}>
                    {table}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container mb={2} justifyContent="flex-start">
            <Grid item>
              <MDButton onClick={() => setSelectedTables(tables)}>Select All</MDButton>
            </Grid>
            <Grid item>
              <MDButton onClick={() => setSelectedTables([])}>Clear</MDButton>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  }

  return (
    <Grid>
      <Button
        fullWidth
        size="large"
        onClick={handleClickOpen}
        sx={{ color: "dark.main", minHeight: "69px" }}
      >
        <ChatIcon fontSize="medium" sx={{ mr: 1, color: "#344767" }} />
        Create a new chat
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <Box component="form" role="form" onSubmit={(event) => handleCreateChat(event)}>
          <DialogTitle>Create a new chat</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DialogContentText>
                  Create a new chat to start using JoonGPT. Make sure that you have created a
                  connection to your data warehouse.
                </DialogContentText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="chat_name"
                  label="Name your chat"
                  fullWidth
                  variant="standard"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="model"
                  defaultValue="gpt-3.5-turbo"
                  options={["gpt-3.5-turbo", "gpt-4-turbo-preview", "gpt-4-turbo", "gpt-4o"]}
                  renderInput={(params) => (
                    <TextField {...params} label="Select a model:" variant="standard" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="caption">
                  GPT3.5 is fast while GPT4 has better reasoning
                </MDTypography>
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  exclusive
                  value={chatType}
                  onChange={(event) => handleChange(event)}
                >
                  D<ToggleButton value="JoonGPT">JoonGPT</ToggleButton>
                  <ToggleButton value="Data Chat">Data Chat</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {chatType && chatType === "JoonGPT" && (
                <Grid item xs={12} mb={2}>
                  <MDTypography variant="button">
                    JoonGPT is an AI chatbot that can help you with your data questions.
                  </MDTypography>
                </Grid>
              )}
              {chatType && chatType === "Data Chat" && (
                <Container>
                  <Grid item xs={12} mb={2} mt={2}>
                    <MDTypography variant="caption">
                      Data Chat is a chatbot that can generate SQL queries on your dataset and
                      execute them.
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      id="connection"
                      options={connections}
                      getOptionLabel={(option) => option.connection_name}
                      onInputChange={(event) => setSelectedConnection(event.target.innerText)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a connection"
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  {renderSelectSchema()}
                </Container>
              )}
              {errorMessage && (
                <Grid item xs={12} mb={2}>
                  <MDAlert color="error" dismissible onClick={handleAlertClose}>
                    <MDTypography variant="button" color="light" fontWeight="regular">
                      {errorMessage}
                    </MDTypography>
                  </MDAlert>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Create</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Grid>
  );
}

JCCreateChatDialog.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    accessToken: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
  }).isRequired,
  apiHostName: PropTypes.string.isRequired,
  setFetchChatsCalled: PropTypes.func.isRequired,
  setSelectedChat: PropTypes.func.isRequired,
};

export default JCCreateChatDialog;
