import React, { useState, useEffect, useRef } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import joonLogo from "assets/images/favicon.png";
import "./styles/styles.min.css";
import { Grid, Box, Typography, Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useUserContext } from "context/UserContext";
import {
  ChatContainer,
  Sidebar,
  MainContainer,
  MessageSeparator,
  ConversationHeader,
  MessageList,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";

import JCConversationList from "layouts/joonai/chat/JCConverstaionList";
import JCChatHeader from "layouts/joonai/chat/JCChatHeader";
import JCChatContent from "layouts/joonai/chat/JCChatContent";
import JCChatInput from "layouts/joonai/chat/JCChatInput";
import JCCreateChatDialog from "./JCCreateChatDialog";

function Chat() {
  const { user } = useUserContext();
  const [chats, setChats] = useState([]);
  const [fetchChatsCalled, setFetchChatsCalled] = useState(false);
  const [selectedChat, setSelectedChat] = useState({ chat_id: "", messages: [] });
  const [selectedMessages, setSelectedMessages] = useState([]);
  const apiHostName = process.env.REACT_APP_API_HOST_NAME;
  const [sendingMessage, setSendingMessage] = useState("");
  const isMountedRef = useRef(true);
  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  async function fetchChat() {
    if (!selectedChat.chat_id) {
      return;
    }
    try {
      user.auth.currentUser.getIdToken(true);
      const response = await fetch(`${apiHostName}/api/v1/chat/get/${selectedChat.chat_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch chat: ${response.status}`);
      }
      const data = await response.json();
      setSelectedMessages(data);
    } catch (error) {
      throw new Error(error);
    }
  }

  async function fetchChats() {
    if (!fetchChatsCalled) {
      try {
        const response = await fetch(`${apiHostName}/api/v1/chat/list/${user.uid}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch chats: ${response.status}`);
        }
        const data = await response.json();
        setChats(data);
        setSelectedChat(data[0]);
        setFetchChatsCalled(true);
      } catch (error) {
        throw new Error(error);
      }
    }
  }

  useEffect(() => {
    fetchChats();
  }, [fetchChatsCalled]);

  useEffect(() => {
    fetchChat();
  }, [selectedChat]);

  useEffect(() => {
    document.title = "Chat - Joon Solutions";
  }, []);

  return (
    <DashboardLayout>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!fetchChatsCalled}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid padding={3}>
        <div style={{ height: "84vh", position: "relative" }}>
          <MainContainer>
            <Sidebar position="left" scrollable>
              <Grid>
                <JCCreateChatDialog
                  user={user}
                  apiHostName={apiHostName}
                  setFetchChatsCalled={setFetchChatsCalled}
                  setSelectedChat={setSelectedChat}
                />
                <MessageSeparator />
                <JCConversationList
                  chats={chats}
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  setFetchChatsCalled={setFetchChatsCalled}
                />
              </Grid>
            </Sidebar>
            {!selectedChat && (
              <Box padding={3}>
                <Typography variant="body2">Select or create a chat to get started</Typography>
              </Box>
            )}
            {chats
              ?.filter((chat) => chat.chat_id === selectedChat.chat_id)
              ?.map((chat) => (
                <ChatContainer key={chat.chat_id}>
                  <JCChatHeader
                    chat={chat}
                    joonLogo={joonLogo}
                    apiHostName={apiHostName}
                    selectedChatId={selectedChat.chat_id}
                    user={user}
                    setFetchChatsCalled={setFetchChatsCalled}
                    as={ConversationHeader}
                  />
                  <JCChatContent
                    user={user}
                    selectedChat={selectedChat}
                    joonLogo={joonLogo}
                    sendingMessage={sendingMessage}
                    isMountedRef={isMountedRef}
                    apiHostName={apiHostName}
                    selectedMessages={selectedMessages}
                    as={MessageList}
                  />
                  <JCChatInput
                    selectedChat={selectedChat}
                    setSelectedMessages={setSelectedMessages}
                    user={user}
                    apiHostName={apiHostName}
                    sendingMessage={sendingMessage}
                    setSendingMessage={setSendingMessage}
                    isMountedRef={isMountedRef}
                    as={MessageInput}
                  />
                </ChatContainer>
              ))}
          </MainContainer>
        </div>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Chat;
