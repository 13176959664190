import React from "react";
import { MessageInput } from "@chatscope/chat-ui-kit-react";
import PropTypes from "prop-types";
import { getAuth } from "firebase/auth";

function JCChatInput({
  selectedChat,
  setSelectedMessages,
  user,
  apiHostName,
  sendingMessage,
  setSendingMessage,
  isMountedRef,
}) {
  function updateChatValue(message, role) {
    if (!isMountedRef.current) return;
    setSelectedMessages((prevState) => [...prevState, { role, content: message }]);
  }

  function streamChatAnswer(message) {
    if (!isMountedRef.current) return;
    setSelectedMessages((prevState) => {
      const updatedList = Array.isArray(prevState) ? [...prevState] : [];
      if (updatedList.length > 0) {
        updatedList[updatedList.length - 1].content += message;
      }
      return updatedList;
    });
  }

  async function queryData(content) {
    if (!isMountedRef.current) return;
    setSendingMessage("Querying data...");
    try {
      const projectId = selectedChat.connection_details.project_id;
      const response = await fetch(`${apiHostName}/api/v1/connection/query`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          query: content,
          project_id: projectId,
          chat_id: selectedChat.chat_id,
        }),
      });
      const data = await response.json();
      if (response.status === 500) {
        updateChatValue(data.detail, "error");
      } else if (response.status === 200) {
        updateChatValue(data, "data");
      }
      setSendingMessage("");
    } catch (error) {
      setSendingMessage("");
      updateChatValue(error.toString(), "error");
      throw new Error(error);
    }
  }

  async function sendMessage() {
    if (!isMountedRef.current) return;
    setSendingMessage("JoonGPT is typing...");
    try {
      const userMessage = document.getElementById("userMessage").textContent;
      updateChatValue(userMessage, "user");
      const accessToken = await getAuth().currentUser.getIdToken(true);
      const response = await fetch(`${apiHostName}/api/v1/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          chat_id: selectedChat.chat_id,
          user_id: user.uid,
          user_message: userMessage,
        }),
      });
      /* eslint-disable */
      let responseMessage = "";
      const responseBody = new ReadableStream({
        async start(controller) {
          const reader = response.body.getReader();
          updateChatValue("", "assistant");
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            const message = new TextDecoder().decode(value); // decode the data into a string
            controller.enqueue(message); // enqueue the message to the readable stream
            responseMessage += message;
          }
          controller.close();
        },
      });
      const streamReader = responseBody.getReader();
      while (true) {
        const { done, value } = await streamReader.read();
        if (done) break;
        streamChatAnswer(value);
      }

      setSendingMessage("");
      return responseMessage;
      /* eslint-enable */
    } catch (error) {
      setSendingMessage("");
      throw new Error(error);
    }
  }

  async function handleSend() {
    if (!isMountedRef.current) return;
    const responseMessage = await sendMessage();
    if (selectedChat.chat_type === "Data Chat") {
      await queryData(responseMessage);
    }
  }

  return (
    <MessageInput
      placeholder="Ask me a data question"
      attachButton={false}
      id="userMessage"
      sendButton={!sendingMessage}
      autoFocus
      onSend={() => {
        handleSend();
      }}
      style={{
        backgroundColor: "white",
      }}
    />
  );
}

JCChatInput.propTypes = {
  selectedChat: PropTypes.shape({
    chat_id: PropTypes.string,
    chat_type: PropTypes.string,
    connection: PropTypes.shape({
      connection_id: PropTypes.string,
      connection_name: PropTypes.string,
      connection_type: PropTypes.string,
    }),
    connection_details: PropTypes.shape({
      project_id: PropTypes.string,
    }),
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        role: PropTypes.string,
      })
    ),
  }).isRequired,
  setSelectedMessages: PropTypes.func.isRequired,
  user: PropTypes.shape({
    uid: PropTypes.string,
    accessToken: PropTypes.string,
    auth: PropTypes.shape({
      currentUser: PropTypes.shape({
        getIdToken: PropTypes.func,
      }),
    }),
  }).isRequired,
  apiHostName: PropTypes.string.isRequired,
  sendingMessage: PropTypes.string.isRequired,
  setSendingMessage: PropTypes.func.isRequired,
  isMountedRef: PropTypes.shape({
    current: PropTypes.bool,
  }).isRequired,
};

export default JCChatInput;
