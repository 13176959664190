/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useUserContext } from "context/UserContext";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import { TextField } from "@mui/material";

function BasicInfo() {
  const { user } = useUserContext();

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox
        component="form"
        pb={3}
        px={3}
        sx={{
          "& .MuiTextField-root": { backgroundColor: "secondary" },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Display Name"
              placeholder="Your Display Name"
              name="displayName"
              value={user?.displayName ?? ""}
              fullWidth
              disabled
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              placeholder="example@email.com"
              inputProps={{ type: "email", readOnly: true }}
              name="email"
              value={user?.email ?? ""}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Phone Number"
              placeholder="Your Phone Number"
              name="phoneNumber"
              value={user?.phoneNumber ?? ""}
              fullWidth
              disabled
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Organization"
              placeholder="Your Organization"
              name="organization"
              value={user?.organization ?? ""}
              fullWidth
              disabled
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
