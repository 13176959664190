import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { handleSignOut } from "layouts/authentication/FirebaseAuth";
import { app } from "FirebaseConfig";

function SignOut() {
  const auth = getAuth(app);
  const navigate = useNavigate();
  useEffect(() => {
    handleSignOut(auth, navigate);
  }, [auth, navigate]);

  return (
    <div>
      <p>Signing you out...</p>
    </div>
  );
}

export default SignOut;
