import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";

import { useUserContext } from "context/UserContext";
import React, { useState, useEffect } from "react";
import JCAddConnection from "./JCAddConnection";
import JCExistingConnections from "./JCExistingConnections";
import JCModifyConnection from "./JCModifyConnection";

function Connection() {
  const { user } = useUserContext();
  const [connections, setConnections] = useState([]);
  const [fetchConnectionsCalled, setfetchConnectionsCalled] = useState(false);
  const apiHostName = process.env.REACT_APP_API_HOST_NAME;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = "Manage Connection - Joon Solutions";
  }, []);

  return (
    <DashboardLayout>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox mb={12} mt={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={3} mb={4} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Manage Connection
                </MDTypography>
              </MDBox>
              <MDTypography variant="body1" color="secondary">
                Manage connection Joon AI connection to your data warehouse
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            "@media (min-width: 600px)": {
              flexDirection: "row",
            },
          }}
        >
          <Grid item xs={12} md={8} lg={5}>
            <Card>
              <JCExistingConnections
                apiHostName={apiHostName}
                setConnections={setConnections}
                connections={connections}
                fetchConnectionsCalled={fetchConnectionsCalled}
                setfetchConnectionsCalled={setfetchConnectionsCalled}
                setLoading={setLoading}
                user={user}
              />
              <JCModifyConnection
                connections={connections}
                apiHostName={apiHostName}
                user={user}
                setfetchConnectionsCalled={setfetchConnectionsCalled}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={8} lg={5}>
            <JCAddConnection
              user={user}
              apiHostName={apiHostName}
              setfetchConnectionsCalled={setfetchConnectionsCalled}
              setLoading={setLoading}
              loading={loading}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default Connection;
